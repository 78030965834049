import React, { Component } from 'react'

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hyperLinkText: null,
            hyperLinkLink: null
        }
    }

    componentDidMount() {
        this.updateHyperLinks()
    }

    updateHyperLinks = () => {
        switch (window.location.hostname.split('.')[1]) {
            case 'amolgarhwal':
                this.setState({
                    hyperLinkText: 'Amol Garhwal',
                    hyperLinkLink: 'https://amolgarhwal.com'
                })
                break;
            case 'chillingking':
                this.setState({
                    hyperLinkText: 'ChillingKing',
                    hyperLinkLink: 'https://chillingking.com'
                })
                break;
            default:
                this.setState({
                    hyperLinkText: 'ChillingKing',
                    hyperLinkLink: 'https://chillingking.netlify.app'
                })
                break;
        }
    }

    render() {
        return(
            <footer>
                <div className="row footer-row">
                    <div className="col-md-8 footer-nav-div">
                        <ul className="footer-nav">
                            <li><a href="/">About us</a></li>
                            <li><a href="/">Blog</a></li>
                            <li><a href="/">Press</a></li>
                            <li><a href="/">iOS App</a></li>
                            <li><a href="/">Android App</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 footer-links-div">
                        <ul className="social-links">
                            <li><a href="/" aria-label="facebook-social"><i className="ion-social-facebook"></i></a></li>
                            <li><a href="/" aria-label="youtube-social"><i className="ion-social-youtube"></i></a></li>
                            <li><a href="/" aria-label="twitter-social"><i className="ion-social-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <p className="footer-paragraph">
                        Made by <a href={this.state.hyperLinkLink} target="_blank" rel="noreferrer">{this.state.hyperLinkText}</a>.
                    </p>
                </div>
            </footer>
        )
    }
}

export default Footer