import React, { Component } from 'react'
import logo from '../assets/logo.png'
import { Link } from 'gatsby'

class NavigationBar extends Component {
    state = {
        hamburger: false
    }

    toggleHamburger() {
        this.setState({
            hamburger: !this.state.hamburger
        })
    }

    collapseModal() {
        this.setState({ hamburger: false })
    }

    componentDidMount() {
        this.setState({ hamburger: false })
    }

    render() {
        return(
            <React.Fragment>
                <nav className="sticky">
                    <div className="row">
                        <div className="col-md-1 col-4">
                            <Link to="/#" exact="true">
                                <img src={logo} alt="Food Quickie logo" className="logo-black" />
                            </Link>
                        </div>
                        <div className="col-md-11 col-8">
                            <ul className="main-nav js--main-nav">
                                <li><Link to={this.props.id1} exact="true">{this.props.text1}</Link></li>
                                <li><Link to={this.props.id2} exact="true">{this.props.text2}</Link></li>
                                <li><Link to={this.props.id3} exact="true">{this.props.text3}</Link></li>
                                <li><Link to={this.props.id4} exact="true">{this.props.text4}</Link></li>
                                <li><button className="snipkart-buttons snipcart-checkout" aria-label="Cart"><i className="ion-bag"></i></button></li>
                                <li><button className="snipkart-buttons snipcart-customer-signin" aria-label="Login/Register"><i className="ion-android-person"></i></button></li>
                            </ul>
                            <div className={`mobile-nav-icon ${this.props.hide ? 'hide' : ''}`} onClick={this.toggleHamburger.bind(this)}>
                                <i className="ion-navicon-round" />
                            </div>
                        </div>
                    </div>
                </nav>
                <div className={`mobile-nav-modal${!this.state.hamburger?`-hidden`:``}`}>
                    <ul className="modal-nav">
                        <li><a href={this.props.id1} onClick={this.collapseModal.bind(this)}>{this.props.text1}</a></li>
                        <li><a href={this.props.id2} onClick={this.collapseModal.bind(this)}>{this.props.text2}</a></li>
                        <li><a href={this.props.id3} onClick={this.collapseModal.bind(this)}>{this.props.text3}</a></li>
                        <li><a href={this.props.id4} onClick={this.collapseModal.bind(this)}>{this.props.text4}</a></li>
                    </ul>
                </div>
                <div className="mobile-account-modal">
                    <ul className="modal-account">
                        <li><button className="snipkart-buttons snipcart-checkout" aria-label="Cart"><i className="ion-bag"></i></button></li>
                        <li><button className="snipkart-buttons snipcart-customer-signin" aria-label="Login/Register"><i className="ion-android-person"></i></button></li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default NavigationBar